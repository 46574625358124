@import "~styles/config";

.scrollView{
  overflow: scroll;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  height: 100%;
  box-shadow: 0 8px 8px 0 rgba(0,0,0,.25);
  z-index: 300;
  background: $dark-dark-color;
  position: relative;
  display: block;
  height: 100%;
}