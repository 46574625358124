@import "~styles/config";

:global {
  @import "~styles/base";
}
body {
  background: $light-dark-color;
}

::-webkit-scrollbar { 
  display: none; 
}