// Fonts
$font-family: "Konnect", sans-serif;
$font-size: 16px;
$font-size-min: 13px;
$line-height: 1.5;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;

// Colors
$color-background: #ffffff;
$color-font: #000000;
$light-dark-color: #1D1D27;
$dark-dark-color: #101018;
$light-light-color: #F9F9F9;
$color-placeholder: #ffbfcf;
$color-highlight: #FFCE00;

// Timings


// Custom break point (Min)
$min-1800: 1800px;
$min-1440: 1440px;
$min-1280: 1280px;
$min-1200: 1200px;
$min-1080: 1080px;
$min-1024: 1024px;
$min-960: 960px;
$min-840: 840px;
$min-768: 768px;
$min-720: 720px;
$min-640: 640px;
$min-600: 600px;
$min-560: 560px;
$min-540: 540px;
$min-480: 480px;
$min-420: 420px;
$min-375: 375px;
$min-360: 360px;

// Custom break points (Max)
$max-1280: 1279px;
$max-1200: 1199px;
$max-1080: 1079px;
$max-1024: 1023px;
$max-960: 959px;
$max-840: 839px;
$max-768: 767px;
$max-720: 719px;
$max-640: 639px;
$max-600: 599px;
$max-540: 539px;
$max-480: 479px;
$max-420: 419px;
$max-375: 374px;
$max-360: 359px;

// Predefined breakpoints
$min-mobile: $min-420;
$min-tablet: $min-720;
$min-desktop: $min-1080;
