@mixin reset-all {
  padding: 0;
  margin: 0;

  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  vertical-align: baseline;

  border: 0;
}

@mixin reset-heading {
  @include reset-all;
}

@mixin reset-list {
  @include reset-all;

  list-style: none;
}

@mixin reset-button {
  @include reset-all;

  background: transparent;
  appearance: none;

  &:focus {
    outline: none;
  }
}

@mixin reset-input {
  @include reset-all;

  border-radius: 0;
  appearance: none;

  &:focus {
    outline: none;
  }
}

@mixin reset-anchor {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}
