/***
*  This file is imported in components/app-layout/AppLayout.scss
*/

/* stylelint-disable selector-max-type  */
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html,
body {
  min-height: 100vh;
  background: $color-background;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  // always show the vertical scrollbar so that content doesn't jump
  overflow-y: scroll;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}

// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;

  margin: 0;

  font-family: $font-family;
  line-height: $line-height;
  color: $color-font;

  counter-reset: heading-counter;

  // iOS on orientation change
  text-size-adjust: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}
button {
  @include reset-button;
}

// placeholders
input,
textarea,
select {
  &::-webkit-input-placeholder {
    color: $color-placeholder;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $color-placeholder;
  }

  &:-ms-input-placeholder {
    color: $color-placeholder;
  }
}
