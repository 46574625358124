@import "~styles/config";

.menuTab{
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  cursor: pointer;
  filter: grayscale(1);
  &__iconContainer{
    align-self: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  
  &__label {
    margin: 0;
    padding: 0;
    align-self: center;
    font-family: $font-family;
    font-size: 10px;
    margin-top: 3px;
    color: $color-highlight;
  }
  &__active {
    filter: grayscale(0);
  }
}